import * as React from "react";
import {
  Block,
  BlockTitle,
  Button,
  Col,
  Gauge,
  Icon,
  Link,
  List,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  Page,
  Row,
  Segmented,
} from "framework7-react";
import { useEffect } from "react";
import { useActions, useAppState } from "../overmind";
import { Mode } from "../overmind/state";

const CounterListPage = () => {
  const { list } = useAppState().counters;
  const { plusCounter, minusCounter, setIndexInList } = useActions().counters;
  const { nav } = useActions();
  const { createCounter } = useActions().counters;
  const { mode, subscription } = useAppState();
  if (mode === Mode.web) {
    window.location.href = require("../../../../package.json").productWebsite;
  }
  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link onClick={() => createCounter()}>
            <Icon f7="plus" />
          </Link>
        </NavLeft>
        <NavRight>
          <Link onClick={() => nav({ name: "settings" })}>
            <Icon f7="gear" />
          </Link>
        </NavRight>
      </Navbar>
      <List simpleList>
        {list.map(({ id, name, count, share }, idx) => (
          <ListItem key={id} text={count}>
            <span
              id={id}
              onClick={() => {
                setIndexInList({ idx });
                nav({ params: { counterId: id }, name: "counter" });
              }}
            >
              {name}
            </span>
            <div className="item-after">
              {subscription.active && share && (
                <Icon
                  tooltip="Sync to server"
                  color="blue"
                  className="padding-right padding-top-half"
                  size="medium"
                  f7="largecircle_fill_circle"
                />
              )}
              <div className="stepper stepper-small stepper-raised">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    minusCounter({ idx });
                  }}
                  className="stepper-button-minus"
                />
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="stepper-value"
                >
                  {count}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    plusCounter({ idx });
                  }}
                  className="stepper-button-plus"
                />
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};

export default CounterListPage;
