let app;

export default {
  async initialize({ f7 }) {
    app = f7;
  },

  async toast(text) {
    return app.toast
      .create({
        text,
      })
      .open();
  },

  async confirm({
    text,
    title,
    ok,
    cancel,
  }: {
    text: string;
    title: string;
    ok: Function;
    cancel: Function;
  }) {
    return await new Promise<void>((resolve, reject) => {
      app.dialog.confirm(text, title, ok, cancel);
    });
  },

  async prompt({ title, text }) {
    return await new Promise<string>((resolve, reject) => {
      app.dialog.prompt(
        text,
        title,
        function ok(value) {
          resolve(value);
        },
        function cancel() {
          resolve("");
        }
      );
    }).catch((error) => {
      throw error;
    });
  },
  async nav({ name, params }) {
    app.views.main.router.navigate({ name, params });
  },

  async actionsCreate({
    buttons,
    targetEl,
  }: {
    buttons: {
      text: string;
      label?: boolean;
      color?: string;
      bold?: boolean;
      onClick?: Function;
    }[];
    targetEl?: string;
  }) {
    return app.actions.create({ buttons, targetEl });
  },

  async sheetCreate({ content, on }: { content: string; on: any }) {
    return app.actions.create({ content, on, backdrop: true });
  },

  async back() {
    app.views.main.router.back();
  },
};
