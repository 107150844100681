import * as React from "react";
import { render } from "react-dom";
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { config } from "./overmind";

import App from "./App";
import Framework7 from "framework7/lite-bundle";
import Framework7React from "framework7-react";
import "framework7/framework7-bundle.css";
Framework7.use(Framework7React);

const overmind = createOvermind(config, { devtools: "192.168.50.197:3032" });

render(
  <Provider value={overmind}>
    <App />
  </Provider>,
  document.querySelector("#app")
);
