import { Entity, Column, PrimaryGeneratedColumn } from "typeorm";

@Entity("count")
export class Count {
  @PrimaryGeneratedColumn("uuid")
  id: string;

  @Column()
  name: string;

  @Column({ default: 0 })
  count: number;

  @Column({ default: false })
  share: boolean;
}
