// https://stackoverflow.com/questions/9647171/how-to-design-application-settings-table-in-database

import { Entity, Column, PrimaryColumn } from "typeorm";

@Entity("setting")
export class Setting {
  @PrimaryColumn()
  name: string;

  @Column("text")
  value: string;
}
