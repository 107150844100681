import { createConnection } from "typeorm";
import initSqlJs from "sql.js";

let orm;

export default {
  async initialize({ entities }) {
    return new Promise(async (resolve) => {
      const isCordova = !!window["cordova"];
      const isDesktop = !isCordova;

      if (isCordova) {
        console.log("isCordova createConnection");
        document.addEventListener("deviceready", async () => {
          try {
            orm = await createConnection({
              type: "cordova",
              database: "app",
              location: "default",
              logging: true,
              synchronize: true,
              migrationsTransactionMode: "none",
              entities,
            });
            console.log("orm after createConnection", orm);
            resolve(true);
          } catch (error) {
            console.error("--");
            console.error(error);
            resolve(false);
          }
        });
      }

      if (isDesktop) {
        try {
          const SQL = await initSqlJs({ locateFile: () => "/sql-wasm.wasm" });
          // @ts-ignore
          window.SQL = SQL;
          console.log("isDesktop createConnection");
          orm = await createConnection({
            type: "sqljs",
            autoSave: true,
            location: "browser", // the key name in Local Storage
            logging: ["error", "query", "schema"],
            synchronize: true,
            entities,
          });

          resolve(true);
        } catch (error) {
          resolve(false);
        }
      }
    });
  },
  getRepository(repository) {
    return orm.getRepository(repository);
  },
  export() {
    return orm.driver.export();
  },
};
