import * as React from "react";
import { useActions, useAppState } from "./overmind";
import { App as F7App, View } from "framework7-react";
import routes from "./routes";
import { Mode } from "./overmind/state";

const f7params = {
  name: "Momo CRM",
  id: "io.momolabs.app",
  routes,
  toast: {
    closeTimeout: 3000,
  },
  dialog: {
    title: "My App",
    // buttonOk: "Done",
  },
};

const App = () => {
  const { f7Init } = useActions();
  const { mode } = useAppState();

  return (
    <F7App
      {...f7params}
      on={{
        init: function () {
          f7Init({ f7: this });
        },
      }}
    >
      {mode === Mode.app && <View main url="/" />}
      {mode === Mode.web && <View main />}
    </F7App>
  );
};

export default App;
