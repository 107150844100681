import * as React from "react";

import { Block, Icon, Link, Tab } from "framework7-react";
import { useActions, useAppState } from "../overmind";

const SettingsTab = ({ id }) => {
  const { activeTab } = useAppState();
  const { onClickTab } = useActions();
  return (
    <Link
      onClick={() => onClickTab({ tab: id })}
      tabLink="#settings"
      tabLinkActive={activeTab === id}
    >
      <Icon f7="person_3" />
      <span className="tabbar-label">Settings</span>
    </Link>
  );
};

const SettingsTabContent = ({ id }) => {
  const { activeTab } = useAppState();

  return (
    <Tab id="Settings" className="page-content" tabActive={activeTab === id}>
      <Block>
        <p>Settings</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam enim
          quia molestiae facilis laudantium voluptates obcaecati officia cum,
          sit libero commodi. Ratione illo suscipit temporibus sequi iure ad
          laboriosam accusamus?
        </p>
      </Block>
    </Tab>
  );
};

const Fabs = () => <></>;

export default {
  id: "settings",
  Tab: SettingsTab,
  Content: SettingsTabContent,
  Fabs,
};
