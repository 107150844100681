import * as React from "react";

import { Block, Fab, Icon, Link, List, ListItem, Tab } from "framework7-react";
import { useActions, useAppState } from "../overmind";
import { createCounter } from "../overmind/namespaces/counters/actions";

const CountersTab = ({ id }) => {
  const { activeTab } = useAppState();
  const { onClickTab } = useActions();

  return (
    <Link
      onClick={() => onClickTab({ tab: id })}
      tabLink="#counters"
      tabLinkActive={activeTab === id}
    >
      <Icon f7="chevron_right_2" />
      <span className="tabbar-label">Counter</span>
    </Link>
  );
};

const CountersTabContent = ({ id }) => {
  const { activeTab } = useAppState();
  const { list } = useAppState().counters;
  const { plusCounter, minusCounter, setIndexInList } = useActions().counters;
  const { nav } = useActions();
  return (
    <Tab id="counters" className="page-content" tabActive={activeTab === id}>
      <List simpleList>
        {list.map(({ id, name, count }, idx) => (
          <ListItem key={id} text={count}>
            <span
              onClick={() => {
                setIndexInList({ idx });
                // @ts-ignore
                nav({ params: { counterId: id }, name: "counter" });
              }}
            >
              {name}
            </span>
            <div className="item-after">
              <div className="stepper stepper-small stepper-raised">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    minusCounter({ idx });
                  }}
                  className="stepper-button-minus"
                />
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="stepper-value"
                >
                  {count}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    plusCounter({ idx });
                  }}
                  className="stepper-button-plus"
                />
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </Tab>
  );
};

const Fabs = ({ id }) => {
  const { activeTab } = useAppState();
  const { createCounter } = useActions().counters;
  return (
    activeTab === id && (
      <Fab onClick={() => createCounter()} position="right-bottom" slot="fixed">
        <Icon f7="plus" />
      </Fab>
    )
  );
};

export default {
  id: "counters",
  Tab: CountersTab,
  Content: CountersTabContent,
  Fabs,
};
