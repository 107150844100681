import AboutPage from "./pages/AboutPage";
import RootPage from "./pages/RootPage";
import CounterPage from "./pages/CounterPage";
import ViewCounterWebPage from "./pages/ViewCounterWebPage";
import SettingsPage from "./pages/SettingsPage";
import UnlockPlusFeaturesPage from "./pages/UnlockPlusFeaturesPage";

export default [
  {
    path: "/",
    component: RootPage,
  },
  {
    path: "/about/",
    component: AboutPage,
  },
  // app
  {
    name: "counter",
    path: "/counter/:counterId",
    component: CounterPage,
  },
  // app

  {
    name: "settings",
    path: "/settings",
    component: SettingsPage,
  },
  {
    name: "unlockplus",
    path: "/unlockplus",
    component: UnlockPlusFeaturesPage,
  },
  // website
  {
    path: "/:counterId",
    component: ViewCounterWebPage,
  },
];
