import * as React from "react";
import {
  Block,
  Button,
  List,
  ListItem,
  Navbar,
  Page,
  Segmented,
} from "framework7-react";

const AboutPage = () => {
  return (
    <Page>
      <Navbar title="My App" />
      <Block>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
          eleifend, elit vitae scelerisque vulputate, tortor velit tempus dui,
          et luctus tellus justo nec velit. Duis scelerisque in tellus et
          pretium. Ut faucibus fringilla risus, ut dapibus nunc vehicula sit
          amet. Donec posuere nunc non fermentum commodo.
        </p>
      </Block>
      <Block>
        <Segmented>
          <Button panelOpen="left">Left Panel</Button>
          <Button panelOpen="right">Right Panel</Button>
        </Segmented>
      </Block>
      <List>
        {[1, 2, 3].map((n) => (
          <ListItem key={n} title={`Item ${n}`} />
        ))}
      </List>
    </Page>
  );
};

export default AboutPage;
