import { Context } from "../../index";
import { v4 as uuid } from "uuid";
import { Counter } from "./state";
import { Count } from "../../../entities/Count";
import { Socket as PhoenixSocket } from "phoenix";
import { subscriptions } from "../../effects";
import { pipe, debounce, Overmind } from "overmind";
import { Mode } from "../../state";
import { rehydrate } from "overmind";

export const stageForScreenshot = async ({
  state,
  effects,
  actions,
}: Context) => {
  state.counters.list = [
    {
      id: "6782f622-cfa8-4392-9138-15ffd214b036",
      name: "🐈 Cat",
      count: 3,
    },
    {
      id: "a0bc47c6-2460-4a3a-ba17-1be297823992",
      name: "🐕 Dog",
      count: 1,
    },
    {
      id: "07b13d76-8e9a-42d3-af16-6b9189702db3",
      name: "🖖 Vulcan",
      count: 4,
    },
    {
      id: "9c247c30-feeb-46a6-b43b-51acad9d46de",
      name: "👽 Alien",
      count: 1,
    },
  ];
  state.counters.list.map((counter) => {
    actions.counters.saveCount({
      ...counter,
    });
  });
};

export const onInitCounterModule = async ({
  state,
  effects,
  actions,
}: Context) => {
  console.log("[ overmind ]: onInitCounterModule");
  if (state.mode === Mode.app) {
    actions.counters.hydrateCountersList();
    effects.gql.initialize({
      // @ts-ignore
      endpoint: require("../../../../../../package.json").graphqlServer,
      headers: () => ({
        authorization: `Bearer ${state.settings.key}`,
      }),
    });
  }
  if (state.mode === Mode.web) {
    PhoenixSocket.prototype.endPointURL = function () {
      return require("../../../../../../package.json").graphqlServerWss;
    };
    effects.subscriptions.initialize({
      onConnecting: actions.counters.onConnecting,
      onConnected: actions.counters.onConnected,
      onReconnecting: actions.counters.onReconnecting,
      onReconnected: actions.counters.onReconnected,
      onDisconnected: actions.counters.onDisconnected,
      onKeepalive: actions.counters.onKeepalive,
    });

    const thePath = location.href;
    const lastItem = thePath.substring(thePath.lastIndexOf("/") + 1);
    let counterId = lastItem.split("?")[0];

    effects.subscriptions.subcribeToCount(actions.counters.onCountChange, {
      id: counterId,
    });
  }
};

//
// EVENTS FROM GQL
//

export const onKeepalive = async ({ state, effects, actions }: Context) => {
  state.counters.heartbeat = true;
  await new Promise((resolve) => setTimeout(resolve, 300));
  state.counters.heartbeat = false;
};
export const onConnecting = async ({ state, effects, actions }: Context) => {
  state.counters.connected = false;
};
export const onConnected = async ({ state, effects, actions }: Context) => {
  state.counters.connected = true;
};
export const onReconnecting = async ({
  state,
  effects,
  actions,
}: Context) => {};
export const onReconnected = async ({ state, effects, actions }: Context) => {};
export const onDisconnected = async ({ state, effects, actions }: Context) => {
  state.counters.connected = false;
};

//
//
//
export const onCountChange = async (
  { state, effects, actions }: Context,
  { count, name }
) => {
  try {
    state.counters.currentCounter.count = count[0].count;
    state.counters.currentCounter.name = count[0].name || "";
  } catch (error) {}
};

export const hydrateCountersList = async ({
  state,
  effects,
  actions,
}: Context) => {
  const counter = await effects.orm
    .getRepository(Count)
    .createQueryBuilder("count")
    .getMany();

  state.counters.list = counter;

  if (state.isTakingScreenshot) {
    actions.counters.stageForScreenshot();
  }
};

export const createCounter = async ({ state, effects, actions }: Context) => {
  const name = await effects.f7.prompt({
    title: "New Counter",
    text: "Enter a name for this counter.",
  });

  if (name) {
    const counter: Counter = {
      id: uuid(),
      name,
      count: 0,
    };
    state.counters.list.push(counter);
    actions.counters.saveCount({
      ...counter,
    });
  }
};

export const updateCountDebounce = pipe(
  debounce(200),
  async ({ actions, effects, state }, { idx }: { idx: number }) => {
    if (state.subscription.active && state.counters.list[idx].share) {
      const { id, count } = state.counters.list[idx];
      try {
        await effects.gql.mutations.updateCount({ id, count });
      } catch (e) {
        effects.f7.toast("Error syncing count");
      }
    }
  }
);

const plusCounterInList = (
  { state, effects, actions }: Context,
  { idx }: { idx: number }
) => {
  state.counters.list[idx].count++;
  return {
    idx,
  };
};
const minusCounterInList = (
  { state, effects, actions }: Context,
  { idx }: { idx: number }
) => {
  state.counters.list[idx].count--;
  return {
    idx,
  };
};

export const plusCounter = pipe(
  plusCounterInList,
  ({ state, effects, actions }: Context, { idx }: { idx: number }) => {
    // state.counters.list[idx].count++;

    actions.counters.saveCount({
      ...state.counters.list[idx],
    });

    if (state.counters.currentCounter.id) {
      state.counters.currentCounter.count++;
    }
    return {
      idx,
    };
  },
  updateCountDebounce
);

export const saveCount = async (
  { state, effects, actions }: Context,
  {
    id,
    name,
    count,
    share,
  }: { id: string; name: string; count: number; share?: boolean }
) => {
  const c = new Count();
  c.id = id;
  c.name = name;
  c.count = count;
  c.share = share || false;
  const cr = effects.orm.getRepository(Count);
  await cr.save(c, { transaction: false });
};
export const minusCounter = pipe(
  minusCounterInList,
  ({ state, effects, actions }: Context, { idx }: { idx: number }) => {
    // state.counters.list[idx].count--;
    actions.counters.saveCount({
      ...state.counters.list[idx],
    });
    if (state.counters.currentCounter.id) {
      state.counters.currentCounter.count--;
    }
    return {
      idx,
    };
  },
  updateCountDebounce
);

export const getCounter = async (
  { state, effects, actions }: Context,
  { id }: { id: string }
) => {
  const counter = await effects.orm.getRepository(Count).findOne({
    where: {
      id,
    },
  });

  state.counters.currentCounter = counter;
};

export const setIndexInList = async (
  { state, effects, actions }: Context,
  { idx }: { idx: number }
) => {
  state.counters.indexInList = idx;
  actions.counters.reset();
};

export const reset = async ({ state, effects, actions }: Context) => {
  state.counters.currentCounter.count = 0;
  state.counters.currentCounter.name = "";
  state.counters.currentCounter.id = "";
};

export const editName = async (
  { state, effects, actions }: Context,
  { indexInList }: { indexInList: number }
) => {
  const name = await effects.f7.prompt({
    title: "Edit name",
    text: "Enter a new name for this counter.",
  });

  if (name) {
    state.counters.list[indexInList].name = name;
    actions.counters.saveCount({
      ...state.counters.list[indexInList],
    });
    state.counters.currentCounter.name = name;

    try {
      const { update_count_by_pk } =
        await effects.gql.mutations.updateCountName({
          id: state.counters.list[indexInList].id,
          countName: name,
        });
    } catch (e) {
      effects.f7.toast("Error update count name on server");
    }
  }
};

export const trash = async (
  { state, effects, actions }: Context,
  { indexInList, counterId }: { indexInList: number; counterId: string }
) => {
  try {
    await effects.orm.getRepository(Count).delete({
      id: counterId,
    });
    state.counters.list.splice(indexInList, 1);
    effects.f7.back();
  } catch (error) {
    console.error(error);
  }
};

export const subscribeToCounter = async (
  { state, effects, actions }: Context,
  { indexInList, counterId }: { indexInList: number; counterId: string }
) => {
  try {
    await effects.orm.getRepository(Count).delete({
      id: counterId,
    });
    state.counters.list.splice(indexInList, 1);
    effects.f7.back();
  } catch (error) {
    console.error(error);
  }
};

export const shareCount = async (
  { state, effects, actions }: Context,
  {
    name,
    count,
    counterId,
    indexInList,
  }: { name: string; count: number; counterId: string; indexInList: number }
) => {
  if (state.subscription.active) {
    try {
      const { count_by_pk } = await effects.gql.queries.getCount({
        id: counterId,
      });

      let shareOn;
      // not null
      if (count_by_pk) {
        console.log(count_by_pk);
      }

      if (!count_by_pk) {
        const { insert_count_one } = await effects.gql.mutations.insertCountOne(
          {
            id: counterId,
            count,
          }
        );
        if (count_by_pk) {
          shareOn = true;
          // state.counters.currentCounter.share = true;
        }
      }
      if (count_by_pk) {
        shareOn = true;
        // state.counters.currentCounter.share = true;
      }

      if (shareOn) {
        state.counters.currentCounter.share = true;
        state.counters.list[indexInList].share = true;
        actions.counters.saveCount({
          ...state.counters.currentCounter,
          // ...state.counters.list[indexInList],
        });
      }
    } catch (e) {
      console.log(e);
      // if (
      //   e.response.errors[0].message ===
      //   'Uniqueness violation. duplicate key value violates unique constraint "count_pkey"'
      // ) {
      //
      // }
    }

    const url = require("../../../../../../package.json").webAppUrlDev;
    const shareLink = url + counterId + "?name=" + name;

    if (state.device.isDesktop) {
      navigator.clipboard.writeText(shareLink);
    } else {
      await effects.cordova.shareUrl(shareLink, "Hi, Check this counter");
    }
  }

  if (!state.subscription.active) {
    effects.f7.confirm({
      title: "Feature locked",
      text: "A plus subscription is required. Find out more?",
      ok: () => {
        actions.back();
        setTimeout(() => {
          actions.nav({ name: "unlockplus" });
        }, 800);
      },
      cancel: () => {},
    });
  }
};

export const setSyncToServer = async (
  { state, effects, actions }: Context,
  { value, indexInList }: { value: boolean; indexInList: number }
) => {
  try {
    state.counters.currentCounter.share = value;
    state.counters.list[indexInList].share = value;
    actions.counters.saveCount({
      ...state.counters.currentCounter,
    });
  } catch (error) {
    console.error(error);
  }
};

export const tapTicker = async ({ state, effects, actions }: Context) => {
  state.counters.tickerFontSize = state.counters.tickerFontSize + 1;

  if (state.counters.tickerFontSize % 11 === 0) {
    effects.f7.toast("Reached biggest text");
  }

  if (state.counters.tickerFontSize % 12 === 0) {
    const defaultVal = 2;
    state.counters.tickerFontSize = defaultVal;
  }
};
