let store;

const {
  plusSubscriptionId,
  receiptValidator,
} = require("../../../../../package.json");
export default {
  async initialize({ storeOnError, storeOnUpdated }) {
    document.addEventListener("deviceready", () => {
      // @ts-ignore
      store = window.store;
      store.verbosity = 4;
      store.register([
        { id: plusSubscriptionId, type: store.PAID_SUBSCRIPTION },
      ]);

      store.validator = receiptValidator;

      store.error(function (error) {
        storeOnError({ error });
      });

      store.when("subscription").updated(function (product) {
        storeOnUpdated({ product });
      });

      store
        .when("product")
        .approved((p) => p.verify())
        .verified((p) => p.finish());

      store.refresh();
    });
  },
  order(id) {
    store.order(id);
  },
};
