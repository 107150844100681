import * as React from "react";
import {
  Block,
  BlockTitle,
  Button,
  Icon,
  List,
  ListItem,
  Navbar,
  Page,
  Segmented,
} from "framework7-react";
import { useActions, useAppState } from "../overmind";
import { format } from "date-fns";

const SettingsPage = () => {
  const { unlockPlus, nav } = useActions();
  const { subscription } = useAppState();

  return (
    <Page>
      <Navbar backLink="Back" backLinkShowText title="Settings" />
      <List mediaList inset>
        <ListItem
          onClick={() => nav({ name: "unlockplus" })}
          link="#"
          title="Unlock Plus"
          subtitle="Find out more"
        >
          <Icon slot="media" f7="lock_open" />
        </ListItem>
      </List>
    </Page>
  );
};

export default SettingsPage;
