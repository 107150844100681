import * as React from "react";
import { Icon, Link } from "framework7-react";
import { useActions, useAppState } from "../overmind";
import Flip from "../components/Flip";
const params = new URLSearchParams(window.location.search);

const ViewCounterWebPage = ({ counterId }) => {
  const { currentCounter, connected, heartbeat, tickerFontSize } =
    useAppState().counters;
  const { count } = currentCounter;
  const { tapTicker } = useActions().counters;
  const counterName = params.get("name");

  return (
    <>
      <div
        className="page-content counter-viewer-background"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="counter-header">
          {currentCounter.name || counterName}
        </div>
        {/*<div>{count}</div>*/}
        {/* @ts-ignore */}
        <Flip value={count} size={tickerFontSize} />
        <Icon
          style={{
            padding: "2rem",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          tooltip={
            connected ? "Connected" : "Disconnected"
            // connected ? (heartbeat ? "Heartbeat" : "Connected") : "Disconnected"
          }
          size="large"
          color={connected ? "green" : "red"}
          // color={connected ? (heartbeat ? "blue" : "green") : "red"}
          f7="circle_fill"
        />
        <Link
          onClick={() => tapTicker()}
          style={{
            padding: "2rem",
            position: "absolute",
            bottom: 0,
            left: 0,
            color: "#3a3c3f",
          }}
          // tooltip={
          //   connected ? "Connected" : "Disconnected"
          //   // connected ? (heartbeat ? "Heartbeat" : "Connected") : "Disconnected"
          // }
          iconSize={20}
          // color={connected ? "green" : "red"}
          // color={connected ? (heartbeat ? "blue" : "green") : "red"}
          iconF7="textformat_size"
        />
      </div>
    </>
  );
};

export default ViewCounterWebPage;
