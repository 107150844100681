class HTTPError extends Error {}
export default {
  async getKey() {
    const response = await fetch("https://key-worker.momolabs.workers.dev/", {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    });

    if (!response.ok) {
      throw new HTTPError(`Fetch error: ${response.statusText}`);
    }

    const json = await response.json();
    return json;
  },
};
