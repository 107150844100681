import * as React from "react";
import { useEffect } from "react";
import {
  Block,
  BlockTitle,
  Button,
  Col,
  Gauge,
  Icon,
  Link,
  List,
  ListButton,
  ListItem,
  Navbar,
  NavRight,
  Page,
  Row,
  Toggle,
} from "framework7-react";
import { useActions, useAppState } from "../overmind";
import { Mode } from "../overmind/state";

const CounterPage = ({ counterId }) => {
  const {
    getCounter,
    minusCounter,
    plusCounter,
    editName,
    trash,
    shareCount,
    setSyncToServer,
  } = useActions().counters;
  const { currentCounter, indexInList } = useAppState().counters;

  const { count, id, name, share } = currentCounter;
  const { subscription, mode } = useAppState();

  useEffect(() => {
    if (!id) {
      getCounter({ id: counterId });
    }
  });
  return (
    <Page>
      <Navbar backLink title={name}>
        <NavRight>
          {/*<Link onClick={() => shareCount({ counterId, count, indexInList })}>*/}
          {/*  <Icon className="margin-right" f7="square_arrow_up" />*/}
          {/*</Link>*/}
          <Link onClick={() => editName({ indexInList })}>
            <Icon className="margin-right" f7="pencil" />
          </Link>
          <Link onClick={() => trash({ counterId, indexInList })}>
            <Icon className="margin-right" f7="trash" />
          </Link>
        </NavRight>
      </Navbar>
      <Block strong className="text-align-center">
        <Gauge
          type="circle"
          size={250}
          borderColor="#2196f3"
          borderWidth={10}
          valueText={`${count}`}
          valueFontSize={41}
          valueTextColor="#2196f3"
        />
      </Block>
      <Block strong>
        <Row tag="p">
          <Col tag="span">
            <Button
              fill
              color="red"
              onClick={() => minusCounter({ idx: indexInList })}
              large
            >
              ▼
            </Button>
          </Col>
          <Col tag="span">
            <Button
              onClick={() => plusCounter({ idx: indexInList })}
              large
              fill
            >
              ▲
            </Button>
          </Col>
        </Row>
      </Block>

      <BlockTitle>Plus subscription required</BlockTitle>
      <List className={subscription.active ? "" : "disabled"}>
        <ListItem>
          <span>Sync to server</span>
          {subscription.active && (
            <Toggle
              checked={share}
              onToggleChange={(value) => {
                setSyncToServer({ value: !value, indexInList });
              }}
            />
          )}
          {!subscription.active && <Toggle checked={false} />}
        </ListItem>
        <ListButton
          onClick={() => {
            shareCount({ name, counterId, count, indexInList });
          }}
          title="Get share link"
        />
      </List>
    </Page>
  );
};

export default CounterPage;
