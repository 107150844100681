// noinspection GraphQLUnresolvedReference

import { gql, graphql } from "overmind-graphql";
import { SubscriptionClient } from "subscriptions-transport-ws";

const MessageTypes = {
  GQL_CONNECTION_KEEP_ALIVE: "ka",
};

// @ts-ignore
const subcribeToCount = gql`
  subscription MySubscription($id: uuid) {
    count(where: { id: { _eq: $id } }) {
      count
      name
    }
  }
`;
// @ts-ignore
const createCount = gql`
  subscription CreateCount($id: uuid) {
    count(where: { id: { _eq: $id } }) {
      count
    }
  }
`;
// @ts-ignore
const insertCountOne = gql`
  mutation insertCountOne($id: uuid = "", $count: Int = 10) {
    insert_count_one(object: { count: $count, id: $id }) {
      count
    }
  }
`;
// @ts-ignore
const updateCount = gql`
  mutation updateCount($count: Int, $id: uuid!) {
    update_count_by_pk(pk_columns: { id: $id }, _set: { count: $count }) {
      count
    }
  }
`;
// @ts-ignore
const updateCountName = gql`
  mutation updateCount($countName: String, $id: uuid!) {
    update_count_by_pk(pk_columns: { id: $id }, _set: { name: $countName }) {
      count
    }
  }
`;

// @ts-ignore
const getCount = gql`
  query getCount($id: uuid!) {
    count_by_pk(id: $id) {
      count
      created_at
      id
      updated_at
      name
    }
  }
`;

type Subscriptions = {
  [index: string]: {
    unsubscribe: () => void;
  };
};

// Custom Subscriptions Effect
export const subscriptions = (() => {
  let client: SubscriptionClient;
  let subscriptions: Subscriptions = {};

  return {
    initialize({
      onConnecting,
      onConnected,
      onReconnecting,
      onReconnected,
      onDisconnected,
      onKeepalive,
    }) {
      client = new SubscriptionClient(
        require("../../../../../../package.json").graphqlServerWss,
        // "wss://counterserver1.momolabs.io/v1/graphql",
        { reconnect: true }
      );

      // https://github.com/apollographql/subscriptions-transport-ws/blob/a8bbabfe375090b44d4a8bd028356ec53d48a23a/src/test/tests.ts#L1172
      const originalOnMessage = client.client.onmessage;
      client.client.onmessage = (dataReceived: any) => {
        let receivedDataParsed = JSON.parse(dataReceived.data);
        if (
          receivedDataParsed.type === MessageTypes.GQL_CONNECTION_KEEP_ALIVE
        ) {
          onKeepalive();
        }
        originalOnMessage(dataReceived);
      };
      client.onConnecting(() => {
        onConnecting();
      });

      client.onConnected(() => {
        onConnected();
      });

      client.onReconnecting(() => {
        onReconnecting();
      });

      client.onReconnected(() => {
        onReconnected();
      });

      client.onDisconnected(() => {
        onDisconnected();
      });

      console.log(client);
    },
    subcribeToCount(action: any, variables: Object = {}) {
      let observable = client.request({
        // @ts-ignore
        query: subcribeToCount,
        variables,
      });

      subscriptions["subcribeToCount"] = observable.subscribe({
        next: ({ data }) => {
          action(data);
        },
      });
    },
    unsubscribe(subscriptionName: string) {
      subscriptions[subscriptionName].unsubscribe();
    },
  };
})();

export default graphql({
  // subscriptions,
  queries: {
    getCount,
  },
  mutations: {
    insertCountOne,
    updateCount,
    updateCountName,
  },
});
