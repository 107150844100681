import { Overmind, debounce, pipe, rehydrate } from "overmind";
import { Context } from ".";
import { Setting } from "../entities/Setting";
import { arrayToObject } from "../utils/array";
import { Count } from "../entities/Count";
import { Mode } from "./state";
import { actions } from "./namespaces/counters";

export const onInitializeOvermind = async (
  { state, effects, actions }: Context,
  instance: Overmind<Context>
) => {
  console.log("[ overmind ]: onInit");
  effects.cordova.initialize({
    onResume: actions.onResume,
    onPause: actions.onPause,
  });
  if (state.mode === Mode.web) {
    actions.counters.onInitCounterModule();
    instance.reaction(
      ({ counters }) => counters.tickerFontSize,
      (tickerFontSize) => effects.storage.saveTickerFontSize(tickerFontSize)
    );
    state.counters.tickerFontSize = effects.storage.getTickerFontSize() || 2;
  }
  if (state.mode === Mode.app) {
    const ok = await effects.orm.initialize({
      entities: [
        Count,
        Setting,
        // Author,
        // Post,
        // Category
      ],
    });
    state.subscription = effects.storage.getSubscription();
    instance.reaction(
      ({ subscription }) => subscription,
      (subscription) => effects.storage.saveSubscription(subscription),
      { nested: true }
    );
    console.log("orm init ok", ok);
    // await actions.testOrm();
    console.log("initSettings");
    await actions.initSettings();
    if (!state.settings.key) {
      await actions.getKey();
    }
    console.log("after initsettings");

    effects.store.initialize({
      storeOnUpdated: actions.storeOnUpdated,
      storeOnError: actions.storeOnError,
    });
    actions.counters.onInitCounterModule();
    actions.checkSubscriptionIsExpired();
    // await actions.saveOrmToFile();
  }
};

export const getKey = async ({ state, effects, actions }: Context) => {
  console.log("getKey");
  try {
    const { token } = await effects.worker.getKey();
    const setting = new Setting();
    setting.name = "key";
    setting.value = JSON.stringify({
      name: "key",
      value: token,
    });
    const settingRepo = effects.orm.getRepository(Setting);
    await settingRepo.save(setting, { transaction: false });
    state.settings.key = token;
  } catch (error) {
    console.error(error);
  }
};

export const onResume = async ({ state, effects, actions }: Context) => {
  actions.checkSubscriptionIsExpired();
};

export const onPause = async ({ state, effects, actions }: Context) => {};

export const f7Init = ({ effects }, { f7 }: { f7: any }) => {
  effects.f7.initialize({ f7 });
};

export const onClickTab = async (
  { state, effects, actions }: Context,
  { tab }: { tab: string }
) => {
  state.activeTab = tab;
};

export const initSettings = async (
  { state, effects, actions }: Context,
  overmindInstance: Overmind<Context>
) => {
  const { orm } = effects;
  const settingsArray = await orm
    .getRepository(Setting)
    .createQueryBuilder("setting")
    .getMany();

  settingsArray.forEach(({ name, value }) => {
    state.settings[name] = JSON.parse(value).value;
  });

  const settingsObject = arrayToObject(settingsArray, (item) => item.name);
  // console.log("settings", settingsObject);

  const { darkMode, key } = settingsObject;

  if (!darkMode) {
    // console.log("noSet", darkMode);
    const setting = new Setting();
    setting.name = "darkMode";
    setting.value = JSON.stringify({
      name: "darkMode",
      value: false,
    });
    const settingRepo = orm.getRepository(Setting);
    await settingRepo.save(setting, { transaction: false });
  }
  if (!key) {
    // console.log("noSet", darkMode);
    const setting = new Setting();
    setting.name = "key";
    setting.value = JSON.stringify({
      name: "key",
      value: "",
    });
    const settingRepo = orm.getRepository(Setting);
    await settingRepo.save(setting, { transaction: false });
  }
};

export const testOrm = async (
  { state, effects, actions }: Context,
  overmindInstance: Overmind<Context>
) => {
  const { orm } = effects;
  // console.log("orm", orm);

  // const count = new Count();
  // count.name = "test";
  // count.count = -2;
  // const cr = orm.getRepository(Count);
  // await cr.save(count);

  // document.write("Writing new post...<br>");
  //
  // const category1 = new Category();
  // category1.name = "TypeScript";
  //
  // const category2 = new Category();
  // category2.name = "Programming";
  //
  // const author = new Author();
  // author.name = "Person";
  //
  // const post = new Post();
  // post.title = "Control flow based type analysis";
  // post.text = `TypeScript 2.0 implements a control flow-based type analysis for local variables and parameters.`;
  // post.categories = [category1, category2];
  // post.author = author;
  //
  // const postRepository = orm.getRepository(Post);
  // await postRepository.save(post);
  //
  // document.write("<br>Post has been save:<br>");
  // document.write("<pre>", JSON.stringify(post, null, 2), "</pre>");
  // console.log("Post has been saved: ", post);
};

export const saveOrmToFile = async (
  { state, effects, actions }: Context,
  overmindInstance: Overmind<Context>
) => {
  const { orm } = effects;
  var arraybuff = orm.export();
  var blob = new Blob([arraybuff]);

  blob = blob.slice(0, blob.size, "application/vnd.sqlite3");

  var a = document.createElement("a");
  a.id = "click";
  a.href = window.URL.createObjectURL(blob);
  a.download = "sql.db";
  a.click();
};

export const nav = (
  { state, effects, actions }: Context,
  { name, params }: { name: string; params?: any }
) => {
  effects.f7.nav({ name, params });
};
export const back = ({ state, effects, actions }: Context) => {
  effects.f7.back();
};
export const restorePlus = async ({ state, effects, actions }: Context) => {};

const { plusSubscriptionId } = require("../../../../package.json");
export const unlockPlus = async ({ state, effects, actions }: Context) => {
  effects.store.order(plusSubscriptionId);
};

export const storeOnUpdated = pipe(
  async (
    { state, effects, actions }: Context,
    { product }: { plusSubscriptionId: any; product: any }
  ) => {
    if (product.owned) {
      state.subscription.id = product.id;
      state.subscription.active = true;
      state.subscription.expiryDate = new Date(product.expiryDate).getTime();
    }

    state.storeState = product.state;
    // if (product.owned === false) {
    //   state.subscription.id = "";
    //   state.subscription.active = false;
    //   state.subscription.expiryDate = undefined;
    // }
  }
);
export const storeOnExpired = async (
  { state, effects, actions }: Context,
  { product }: { plusSubscriptionId: any; product: any }
) => {
  console.log(product);
  // if (product.owned) {
  //   state.subscription.id = product.id;
  //   state.subscription.active = true;
  //   state.subscription.expiryDate = new Date(product.expiryDate);
  // }
};
export const storeOnError = async (
  { state, effects, actions }: Context,
  { error }: { error: any }
) => {
  console.log(error);
  // effects.f7.nav({ name, params });
};

export const checkSubscriptionIsExpired = async ({
  state,
  effects,
  actions,
}: Context) => {
  if (state.subscription.expiryDate < new Date().getTime()) {
    state.subscription.id = "";
    state.subscription.active = false;
    state.subscription.expiryDate = undefined;
  }
};
