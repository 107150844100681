import * as React from "react";
import {
  Block,
  BlockTitle,
  Button,
  Icon,
  List,
  ListButton,
  ListItem,
  Navbar,
  Page,
  Preloader,
  Segmented,
} from "framework7-react";
import { useActions, useAppState } from "../overmind";
import { format } from "date-fns";

const features = require("../../../../features.json").plus;

const UnlockPlusFeaturesPage = () => {
  const { unlockPlus, restorePlus } = useActions();
  const { subscription, storeState } = useAppState();

  const storePending = storeState === "initiated" || storeState === "approved";

  return (
    <Page>
      <Navbar backLink="Back" backLinkShowText title="Plus features" />
      <List mediaList>
        {features.map(({ name, description, f7Icon }, index) => {
          return (
            <ListItem key={index} title={name} text={description}>
              <Icon slot="media" f7={f7Icon} />
            </ListItem>
          );
        })}

        <ListButton
          className={`${subscription.active && "disabled"}`}
          onClick={() => unlockPlus()}
          title={`${
            subscription.active ? "Unlocked" : "Unlock Plus Subscription"
          }`}
        />
        {/*<ListButton*/}
        {/*  onClick={() => restorePlus()}*/}
        {/*  title="Restore Plus Subscription"*/}
        {/*  color="orange"*/}
        {/*/>*/}
      </List>
      {storePending && (
        <Block strong className="text-align-center">
          <Preloader color="multi" />
        </Block>
      )}
      {!storePending && subscription.active && subscription.expiryDate && (
        <>
          <BlockTitle>Plus (Monthly)</BlockTitle>
          <Block>{`Expires ${format(
            new Date(subscription.expiryDate || Date.now()),
            "MMM dd, yyyy"
          )}`}</Block>
        </>
      )}
    </Page>
  );
};

export default UnlockPlusFeaturesPage;
