import root from "../root/index";
import { storage } from "./effects";

export enum Mode {
  app = "app",
  web = "web",
  chromeExtension = "chromeExtension",
}
export enum Env {
  dev = "dev",
  prod = "prod",
}

export type Subscription = {
  id: string;
  active: boolean;
  expiryDate: number;
};

type State = {
  isTakingScreenshot: boolean;
  env: Env;
  mode: Mode;
  activeTab: string;
  settings: {
    key: string;
    darkMode: boolean;
  };
  t: string;
  subscription: Subscription;
  device: any;
  storeState: string;
};

const params = new URLSearchParams(window.location.search);
const isDev = location.href.indexOf("localhost") > -1;
const isProd = location.href.indexOf("momolabs.io") > -1;
let isForWeb = process.env.WEB || params.get("mode") === "web";
let isForApp = process.env.APP || params.get("mode") === "app";

let activeMode;
if (isForApp) {
  activeMode = Mode.app;
}
if (isForWeb) {
  activeMode = Mode.web;
}

let env;
isDev && (env = Env.dev);
isProd && (env = Env.prod);

const isCordova = !!window["cordova"];
const isDesktop = !isCordova;

export const state: State = {
  isTakingScreenshot: false,
  env,
  activeTab: root[0].id,
  settings: {
    key: "",
    darkMode: false,
  },
  mode: activeMode,
  t: process.env.GRAPHQL_ENDPOINT,
  subscription: storage.getSubscription(),
  storeState: "",
  device: {
    isDesktop,
  },
};
