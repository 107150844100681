import { Subscription } from "../state";
import ls, { get, set } from "local-storage";

const params = new URLSearchParams(window.location.search);
const suboverride = params.get("suboverride") === "1";
export const defaultSubscription = {
  id: "",
  active: suboverride || false,
  expiryDate: undefined,
};
export const storage = {
  saveSubscription(subscription: Subscription) {
    localStorage.setItem("subscription", JSON.stringify(subscription));
  },
  getSubscription(): Subscription {
    try {
      const parsed = JSON.parse(
        localStorage.getItem("subscription") ||
          `${<Subscription>{
            ...defaultSubscription,
          }}`
      );
      return parsed;
    } catch (e) {
      return <Subscription>{
        ...defaultSubscription,
      };
    }
  },
  saveTickerFontSize(size: number) {
    set("tickerFontSize", size);
  },
  getTickerFontSize(): number {
    console.log("getticker", get("tickerFontSize"));
    return get("tickerFontSize");
  },
};

export default storage;
