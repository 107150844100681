import { IContext } from "overmind";
import { merge, namespaced } from "overmind/config";

import {
  createStateHook,
  createActionsHook,
  createEffectsHook,
  createReactionHook,
} from "overmind-react";
import { state } from "./state";
import * as actions from "./actions";
import * as effects from "./effects";

import * as counters from "./namespaces/counters";

export const config = merge(
  {
    state,
    actions,
    effects,
  },
  namespaced({
    counters,
  })
);
export type Context = IContext<{
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}>;

export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
export const useReaction = createReactionHook<Context>();

export const Observer: React.FC<{
  children: <T>(context: {
    state: Context["state"];
    actions: Context["actions"];
  }) => T;
}> = ({ children }) => {
  const appState = useAppState();
  const appActions = useActions();

  return children({ state: appState, actions: appActions });
};
