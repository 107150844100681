export type Counter = {
  id: string;
  name: string;
  count: number;
  share?: boolean;
};

export type State = {
  list: Counter[];
  currentCounter: Counter;
  indexInList: number;
  isSubscribing: boolean;
  connected: false;
};

export const state = {
  list: [],
  currentCounter: {
    id: "",
    name: "",
    count: 0,
    share: false,
  },
  indexInList: 0,
  isSubscribing: false,
  connected: false,
  heartbeat: false,
  tickerFontSize: 2,
};
