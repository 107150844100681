export default {
  async initialize({ onResume, onPause }) {
    document.addEventListener("resume", () => onResume(), false);
    document.addEventListener("pause", () => onPause(), false);
  },

  async shareUrl(url, subject) {
    return new Promise((resolve, reject) => {
      var options = {
        subject,
        url,
      };

      var onSuccess = function (result) {
        console.log("Share completed? " + result.completed);
        console.log("Shared to app: " + result.app);
        resolve({ result });
      };

      var onError = function (msg) {
        console.log("Sharing failed with message: " + msg);
        reject();
      };

      // @ts-ignore
      window.plugins.socialsharing.shareWithOptions(
        options,
        onSuccess,
        onError
      );
    });
  },
};
